import React from 'react';

const styles = {
    Button: {
        cursor: 'pointer',
        top: '206px',
        left: '1112px',
        width: '143px',
        height: '31px',
        padding: '0px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row-reverse',
        gap: '7px',
        border: '1px solid #6e1cf4',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
        backgroundColor: '#ffffff',
        color: '#000000',
        fontSize: '14px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '24px',
        outline: 'none',
    },
    Icon: {
        fontSize: '21px',
        width: '21px',
        height: '21px',
        color: '#000000',
        fill: '#000000',
    },
};

const IconComponent = () => (
    <svg style={styles.Icon} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none">
        </path>
        <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z">
        </path>
    </svg>
);

const defaultProps = {
    label: 'SUBSCRIPTION',
    IconComponent,
};

const Subscription = (props) => {
    return (
        <button style={styles.Button}>
            <span>{props.label ?? defaultProps.label}</span>
            {
                props.IconComponent
                    ? <props.IconComponent style={styles.Icon} />
                    : <defaultProps.IconComponent />
            }
        </button>
    );
};

export default Subscription;