import React, { useState } from 'react';
import './MainDrawer.css';
const styles = {
    Card: {
        position: 'fixed',
        top: '9vh',
        left: '6vw',
        height: '91vh',
        width: '339px',
        backgroundColor: '#E3E8EE',
        border: '2px solid #000000',
    },
    CloseIcon: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        width: '25px',
        height: '25px',
        fill: '#e3162a',
    },
    Heading: {
        marginTop: '40px',
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '16px',
    },
    IconDiv: {

    }
};

const MainDrawer = ({ onClose, content }) => {
    return (
        <div className="drawer_container">
            <div>
                <div className="heading">{content.heading}</div>
                <div>{content.body}</div>
            </div>
        </div>
    );
};

export default MainDrawer;
