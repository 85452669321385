import React, { useState } from 'react';
import './header.css'
import Title from './headerelements/Title';
import UserIcon from './headerelements/UserIcon';
import Notification from './headerelements/Notification';
import People from './headerelements/People';
import Plus from './headerelements/Plus';


const Header = ({ toggleUserDetails }) => {

    return (
        <div className='header'>
            <div className="header-left">
                <Title />
            </div>
            <div className="header-right">
                <Plus />
                <People />
                <Notification />
                <UserIcon onClick={toggleUserDetails} />
            </div>

        </div>
    );
};

export default Header;