import React, { useState } from 'react';
import Dashboard from './sidebarelements/Dashboard';

import Appsync from './sidebarelements/Appsync';
import Labels from './sidebarelements/Labels'
import Widget from './sidebarelements/Widget';
import Settings from './sidebarelements/Settings';

const styles = {
    Card: {
        position: 'fixed',
        top: '9vh',
        left: '0px',
        width: '6vw',
        height: '91vh',
        backgroundColor: '#16162a',
        boxShadow: '1px 0px 12px rgba(3,3,3,0.08)',
    },
};

const SideBar = ({ onSelect }) => {
    const [selectedItem, setSelectedItem] = useState('Dashboard');
    const handleSelection = (item) => {
        setSelectedItem(item);
        onSelect(item);
    };
    return (
        <div style={styles.Card}>
            <Dashboard
                isSelected={selectedItem === 'Dashboard'}
                onClick={() => handleSelection('Dashboard')}
            />
            <Appsync
                isSelected={selectedItem === 'Appsync'}
                onClick={() => handleSelection('Appsync')}
            />
            <Labels
                isSelected={selectedItem === 'Labels'}
                onClick={() => handleSelection('Labels')}
            />
            <Widget
                isSelected={selectedItem === 'Widget'}
                onClick={() => handleSelection('Widget')}
            />
            <Settings
                isSelected={selectedItem === 'Settings'}
                onClick={() => handleSelection('Settings')}
            />
        </div>
    );
};

export default SideBar;