import "./Signup.css";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Signup() {
    const [displayText, setDisplayText] = useState("");
    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
    });
    const [showForm, setShowForm] = useState(true);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setShowForm(false);
        setDisplayText(
            "An account verification email has been sent to your email address"
        );

        const data = {
            first_name: form.firstName,
            last_name: form.lastName,
            email_id: form.email,
            platform_type: "web",
        };

        const auth = window.catalyst.auth;
        try {
            const signupResponse = await auth.signUp(data);
            if (signupResponse.status === 200) {
                setTimeout(() => {
                    navigate("/login"); // Redirect to login
                }, 3000);
            } else {
                alert(signupResponse.message);
            }
        } catch (error) {
            console.error("Signup Error: ", error);
            alert("An error occurred during signup.");
        }
    };

    return (
        <div id="signup" className="signup-container">
            <header className="signup-header">
                <h1>Sign Up to Microservices</h1>
            </header>
            <div className="signup-form-wrapper">
                {showForm ? (
                    <form onSubmit={handleSubmit} className="signup-form">
                        <h2>Sign Up</h2>
                        <p>Please fill this form to sign up for a new account.</p>
                        <label htmlFor="firstName">
                            <b>First Name</b>
                            <input
                                name="firstName"
                                className="form-input"
                                placeholder="Enter First Name"
                                value={form.firstName}
                                onChange={(e) =>
                                    setForm({ ...form, firstName: e.target.value })
                                }
                                required
                            />
                        </label>
                        <label htmlFor="lastName">
                            <b>Last Name</b>
                            <input
                                className="form-input"
                                placeholder="Enter Last Name"
                                value={form.lastName}
                                onChange={(e) =>
                                    setForm({ ...form, lastName: e.target.value })
                                }
                                required
                            />
                        </label>
                        <label htmlFor="email">
                            <b>Email</b>
                            <input
                                className="form-input"
                                placeholder="Enter email address"
                                value={form.email}
                                onChange={(e) =>
                                    setForm({ ...form, email: e.target.value })
                                }
                                required
                            />
                        </label>
                        <p className="terms">
                            By creating an account, you agree to our{" "}
                            <a
                                href="https://www.zoho.com/catalyst/terms.html"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms & Conditions
                            </a>
                            .
                        </p>
                        <div className="form-actions">
                            <input type="submit" value="Sign Up" className="btn signup-btn" />
                            <button
                                type="button"
                                className="btn cancel-btn"
                                onClick={() => navigate("/login")}
                            >
                                Back to Sign-in
                            </button>
                        </div>
                    </form>
                ) : (
                    <p className="success-message">{displayText}</p>
                )}
            </div>
        </div>
    );
}

export default Signup;
