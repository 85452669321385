import React, { useEffect, useState } from 'react';
import './datatable.css'; // Import the CSS file

const DataTable = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch('https://microservices-60034551916.development.catalystserverless.in/server/gettestTableRow/execute')
            .then((response) => response.json())
            .then((result) => {
                const parsedData = JSON.parse(result.output);
                setData(parsedData.map(item => item.TestTable));
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    return (
        <div className="table-container">
            <div className='table_div'>
                <h1>Test Table Data</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Row ID</th>
                            <th>Name</th>
                            <th>Modified Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.ROWID}</td>
                                <td>{item.Name}</td>
                                <td>{item.MODIFIEDTIME}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DataTable;
