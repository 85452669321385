import UserProfile from "./UserProfile";
import LoginPage from "./LoginPage.js";
import Screen from "./components/Screen.js";

import { useEffect, useState, useRef } from "react";
function Layout() {
    const [isFetching, setIsFetching] = useState(true);
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [userDetails, setUserDetails] = useState({
        firstName: "",
        lastName: "",
        mailid: "",
        timeZone: "",
        createdTime: "",
    });
    useEffect(() => {
        console.log("Catalyst Object:", window.catalyst);
        if (window.catalyst && window.catalyst.auth) {

            window.catalyst.auth
                .isUserAuthenticated()
                .then((result) => {
                    // console.log(result.content)
                    setUserDetails({
                        firstName: result.content.first_name,
                        lastName: result.content.last_name,
                        mailid: result.content.email_id,
                        timeZone: result.content.time_zone,
                        createdTime: result.content.created_time,
                        orgId: result.content.org_id,
                        userId: result.content.user_id,
                    });
                    setIsUserAuthenticated(true);
                })
                .catch((err) => { console.log("Error authenticating user:", err); })
                .finally(() => {
                    setIsFetching(false);
                });
        } else {
            console.log("Catalyst is undefined or not initialized.");
            setIsFetching(false);
        }
    }, []);
    return (
        <>
            {isFetching ? (
                <p>Loading ….</p>
            ) : isUserAuthenticated ? (

                <Screen userDetails={userDetails} />

                // <UserProfile userDetails={userDetails} />
            ) : (
                <LoginPage />
            )}
        </>
    );
}
export default Layout;