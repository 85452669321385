import React, { useCallback } from 'react';
import './details.css';
import UserImage from './UserImage';
import Subscription from './Subscription';
import Logout from './Logout';

const Details = ({ onClose, userDetails }) => {
    const logout = useCallback(() => {
        console.log(userDetails)
        window.catalyst.auth.signOut('/');
    }, []);
    return (
        <div className="details-container">
            <svg
                viewBox="0 0 24 24"
                className="closeIcon"
                onClick={onClose}
            >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
            <div className="user-info">
                <div className="user-icon">
                    <UserImage />
                </div>
                <div className="user-details">
                    <div className="user-name">{userDetails.firstName} {userDetails.lastName}</div>
                    <div className="user-account">{userDetails.mailid}</div>
                </div>
            </div>

            <div className="additional-info">
                <div className="user-id">User ID: {userDetails.userId}</div>
                <div className="org-id">Organization ID: {userDetails.orgId}</div>
            </div>
            <div className='allbuttons'>
                <Subscription />
                <Logout logout={logout} />
            </div>
        </div>
    );
};

export default Details;
