import { useEffect } from "react";
import { Link } from "react-router-dom";
import './login.css'

const LoginPage = () => {
    useEffect(() => {

        window.catalyst.auth.signIn("login");
    }, []);
    return (
        <div className="container">
            <h1 className="title">Sign in to Microservices</h1>
            <div id="login"></div>
            <p className="homepage">
                <b>
                    Don't have an account?{" "}
                    <Link
                        to="/signup"
                        style={{ color: "blue", textDecorationLine: "underline" }}
                    >
                        Sign-up
                    </Link>{" "}
                    now!
                </b>
            </p>
        </div>
    );
};
export default LoginPage;