import React from 'react';
import './chatanddetails.css'
import CommonButton from './CommonButton';
import { ChatIcon } from './chaticon';
import { TalkWithUs } from './talkwithus';
import { WriteToUs } from './Writetous';
import { Community } from './Community';

const ChatandCall = () => {
    return (

        <div className='otherDetails'>
            <div className='needHealpDiv'>
                <p>Need Help?</p>
            </div>
            <div className='allExtras'>
                <CommonButton label='Chat with us' IconComponent={ChatIcon} />
                <CommonButton label='Talk with us' IconComponent={TalkWithUs} />
                <CommonButton label='Write to us' IconComponent={WriteToUs} />
                <CommonButton label='Community' IconComponent={Community} />

            </div>
        </div>

    );
};

export default ChatandCall;