import React from 'react';

const styles = {
    Button: {
        cursor: 'pointer',
        top: '382px',
        left: '0px',
        width: '6vw',
        height: '78px',
        padding: '0px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column-reverse',
        gap: '8px',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        backgroundColor: 'rgba(0,0,0,0)',
        color: '#ffffff',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '21px',
        outline: 'none',
    },
    Icon: {
        fontSize: '24px',
        width: '24px',
        height: '24px',
        color: '#ffffff',
        fill: '#e6e7eb',
    },
    Selected: {
        backgroundColor: '#6e1cf4',
    },
};

const IconComponent = () => (
    <svg style={styles.Icon} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0V0z" fill="none">
        </path>
        <path d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58a.49.49 0 0 0 .12-.61l-1.92-3.32a.488.488 0 0 0-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54a.484.484 0 0 0-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58a.49.49 0 0 0-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z">
        </path>
    </svg>
);

const defaultProps = {
    label: 'Settings',
    IconComponent,
};

const Settings = ({ isSelected, onClick, label, IconComponent }) => {
    return (
        <button
            style={{
                ...styles.Button,
                ...(isSelected ? styles.Selected : {}),
            }}
            onClick={onClick}
        >
            <span>{label ?? defaultProps.label}</span>
            {IconComponent ? <IconComponent style={styles.Icon} /> : <defaultProps.IconComponent />}
        </button>
    );
};

export default Settings;