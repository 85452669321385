import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./Layout";
import Signup from "./Signup";
import Screen from "./components/Screen";
import { useState } from 'react';

function App() {
  // const [userDetails, setUserDetails] = useState({
  //   firstName: "Test",
  //   lastName: "Test",
  //   mailid: "test@gmail.com",
  //   timeZone: "India",
  //   createdTime: "Test",
  // });
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="" element={<Navigate to="/" replace />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </HashRouter>
    // <Screen userDetails={userDetails} />
  );
}
export default App;