import React from 'react';

const styles = {
    HorizontalDivider: {
        top: '255px',
        left: '1096px',
        width: '350px',
        height: '1px',
        backgroundColor: '#c1c1c1',
        borderRadius: '2px',
    },
};

const HorizontalDivider = (props) => {
    return (
        <div style={styles.HorizontalDivider} />
    );
};

export default HorizontalDivider;