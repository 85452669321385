// IconButton.js
import React from 'react';
import './iconbutton.css'; // Add styles here for the button

const IconButton = ({ label, Icon, isSelected, onClick }) => {
    return (
        <button
            className={`icon-button ${isSelected ? 'selected' : ''}`}
            onClick={onClick}
        >
            <Icon />
            <span>{label}</span>
        </button>
    );
};

export default IconButton;
