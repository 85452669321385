import React from 'react';

const styles = {
    Text: {
        color: '#ffffff',
        fontSize: '22px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        lineHeight: '24px',
        textTransform: 'uppercase',
    },
};

const defaultProps = {
    text: 'Microservices',
};

const Title = (props) => {
    return (
        <div style={styles.Text}>
            {props.text ?? defaultProps.text}
        </div>
    );
};

export default Title;