import React, { useState } from 'react';
import './maincontainer.css'


const MainContainer = ({ content }) => {
    return (
        <div className='mainContainer'>{content}</div>
    );
};

export default MainContainer;