import React from 'react';

const styles = {
    Icon: {
        color: '#ffffff',
        fill: '#ffffff',
        fontSize: '30px',
        top: '16px',
        left: '1328px',
        width: '30px',
        height: '30px',
    },
};

const IconComponent = () => (
    <svg style={styles.Icon} viewBox="0 0 24 24">
        <path d="M12 22c1.1 0 2-.9 2-2h-4a2 2 0 0 0 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z">
        </path>
    </svg>
);

const defaultProps = {
    IconComponent,
};

const Notification = (props) => {
    return (
        props.IconComponent
            ? <props.IconComponent style={styles.Icon} />
            : <defaultProps.IconComponent />
    );
};

export default Notification;