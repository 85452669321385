import React from 'react';

const styles = {
    Button: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '74px',
        left: '1112px',
        width: '64px',
        height: '67px',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '100px',
        opacity: 0.6,
        boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
        color: '#030303',
        backgroundColor: '#ffffff',
        outline: 'none',
    },
    Icon: {
        color: '#030303',
        fill: '#030303',
        width: '34px',
        height: '34px',
        fontSize: '34px',
    },
};

const IconComponent = () => (
    <svg style={styles.Icon} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0V0z" fill="none">
        </path>
        <path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 9c2.7 0 5.8 1.29 6 2v1H6v-.99c.2-.72 3.3-2.01 6-2.01m0-11C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z">
        </path>
    </svg>
);

const defaultProps = {
    IconComponent,
};

const UserImage = (props) => {
    return (
        <button style={styles.Button}>
            {
                props.IconComponent
                    ? <props.IconComponent style={styles.Icon} />
                    : <defaultProps.IconComponent />
            }
        </button>
    );
};

export default UserImage;