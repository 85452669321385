import React from 'react';

const styles = {
    Button: {
        cursor: 'pointer',
        top: '61px',
        left: '0px',
        width: '6vw',
        height: '79px',
        padding: '0px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column-reverse',
        gap: '8px',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        backgroundColor: 'rgba(0,0,0,0)',
        color: '#ffffff',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '21px',
        outline: 'none',
    },
    Icon: {
        fontSize: '24px',
        width: '24px',
        height: '24px',
        color: '#ffffff',
        fill: '#e6e7eb',
    },
    Selected: {
        backgroundColor: '#6e1cf4',
    },
};

const IconComponent = () => (
    <svg style={styles.Icon} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none">
        </path>
        <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z">
        </path>
    </svg>
);

const defaultProps = {
    label: 'Dashboard',
    IconComponent,
};

const Dashboard = ({ isSelected, onClick, label, IconComponent }) => {
    return (
        <button
            style={{
                ...styles.Button,
                ...(isSelected ? styles.Selected : {}),
            }}
            onClick={onClick}
        >
            <span>{label ?? defaultProps.label}</span>
            {IconComponent ? <IconComponent style={styles.Icon} /> : <defaultProps.IconComponent />}
        </button>
    );
};

export default Dashboard;