import React from 'react';
import Details from './details/Details';
import HorizontalDivider from './details/HorizontalDivider';
import ChatandCall from './other/Chatsandcalls';

const styles = {
    Card: {
        position: 'fixed',
        top: '9vh',
        right: '-344px',
        width: '344px',
        height: '91vh',
        backgroundColor: '#f2f3f4',
        borderRadius: '4px',
        transition: 'right 0.3s ease',
        zIndex: 100,
    },
    CardVisible: {
        right: '0px',
    },
};

const UserDetails = ({ showUserDetails, onClose, userDetails }) => {
    return (
        <div
            style={{
                ...styles.Card,
                ...(showUserDetails ? styles.CardVisible : {}),
            }}
        >
            <Details onClose={onClose} userDetails={userDetails} />
            <HorizontalDivider />
            <ChatandCall />
            <HorizontalDivider />
            {/* <button onClick={onClose}>Close</button> */}
        </div>
    );
};

export default UserDetails;
