import React from 'react';


const styles = {
    Button: {
        cursor: 'pointer',
        top: '206px',
        left: '1320px',
        width: 'auto',
        height: '31px',
        padding: '0px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row-reverse',
        gap: '7px',
        border: '1px solid #e3162a',
        boxSizing: 'border-box',
        borderRadius: '4px',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
        backgroundColor: '#ffffff',
        color: '#db2424',
        fontSize: '14px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '24px',
        textTransform: 'uppercase',
        outline: 'none',
    },
    Icon: {
        fontSize: '21px',
        width: '21px',
        height: '21px',
        color: '#db2424',
        fill: '#db2424',
    },
};

const IconComponent = () => (
    <svg style={styles.Icon} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none">
        </path>
        <path d="m17 7-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z">
        </path>
    </svg>
);

const defaultProps = {
    label: 'Log Out',
    IconComponent,
};

const Logout = ({ label, IconComponent, logout }) => {
    return (
        <button style={styles.Button} onClick={logout}>
            <span>{label ?? defaultProps.label}</span>
            {IconComponent ? (
                <IconComponent style={styles.Icon} />
            ) : (
                <defaultProps.IconComponent />
            )}
        </button>
    );
};

export default Logout;