import React, { useState, useEffect } from 'react';
import './screen.css';
import Header from './Header';
import SideBar from './Sidebar';
import UserDetails from './sideFloatingbar/UserDetails';
import MainDrawer from './rightsidedrawer/MainDrawer';
import IconButton from './rightsidedrawer/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import MainContainer from './mainContainer/MainContainer';
import DataTable from './Testing/DataTable';

const Screen = ({ userDetails }) => {
    const [showUserDetails, setShowUserDetails] = useState(false);
    const [drawerContent, setDrawerContent] = useState(null);
    const [selectedButton, setSelectedButton] = useState('dashboard'); // Keeps track of the selected button

    const toggleUserDetails = () => {
        setShowUserDetails(!showUserDetails);
    };


    const handleSidebarSelect = (item) => {
        const contentMap = {
            // Dashboard: {
            //     heading: 'My Dashboard',
            //     body: (
            //         <div>
            //             <IconButton
            //                 label="Loyverse POS for Zoho Books"
            //                 Icon={() => <FontAwesomeIcon icon={faCheckCircle} />}
            //                 isSelected={selectedButton === 'dashboard-1'}
            //                 onClick={() => handleButtonClick('dashboard-1')}
            //             />
            //             <IconButton
            //                 label="Loyverse POS for Zoho CRM"
            //                 Icon={() => <FontAwesomeIcon icon={faCheckCircle} />}
            //                 isSelected={selectedButton === 'dashboard-2'}
            //                 onClick={() => handleButtonClick('dashboard-2')}
            //             />
            //         </div>
            //     ),
            // },
            Appsync: {
                heading: 'My Appsync',
                body: (
                    <div>
                        <IconButton
                            label="Loyverse POS for Zoho Books"
                            Icon={() => <FontAwesomeIcon icon={faCheckCircle} />}
                            isSelected={selectedButton === 'appsync-1'}
                            onClick={() => handleButtonClick('appsync-1')}
                        />
                        <IconButton
                            label="Loyverse POS for Zoho CRM"
                            Icon={() => <FontAwesomeIcon icon={faCheckCircle} />}
                            isSelected={selectedButton === 'appsync-2'}
                            onClick={() => handleButtonClick('appsync-2')}
                        />
                    </div>
                ),
            },
            Labels: { heading: 'My Labels', body: 'Labels content here' },
            Widget: { heading: 'My Widget', body: 'Widget content here' },
            Settings: { heading: 'My Settings', body: 'Settings content here' },
        };
        if (item === 'Dashboard') {
            setDrawerContent(null); // Ensure no drawer is opened
            setSelectedButton('dashboard'); // Update selected button for main content
        } else {
            setDrawerContent((prevContent) =>
                prevContent?.heading === contentMap[item]?.heading ? null : contentMap[item]
            );
        }
    };

    const handleButtonClick = (buttonId) => {
        setSelectedButton((prevSelected) => {
            const newSelected = prevSelected === buttonId ? null : buttonId;

            // Update drawerContent to trigger a re-render
            setDrawerContent((prevContent) => {
                if (prevContent) {
                    return {
                        ...prevContent,
                        body: (
                            <div>
                                <IconButton
                                    label="Loyverse POS for Zoho Books"
                                    Icon={() => <FontAwesomeIcon icon={faCheckCircle} />}
                                    isSelected={newSelected === 'dashboard-1'}
                                    onClick={() => handleButtonClick('dashboard-1')}
                                />
                                <IconButton
                                    label="Loyverse POS for Zoho CRM"
                                    Icon={() => <FontAwesomeIcon icon={faCheckCircle} />}
                                    isSelected={newSelected === 'dashboard-2'}
                                    onClick={() => handleButtonClick('dashboard-2')}
                                />
                            </div>
                        ),
                    };
                }
                return prevContent;
            });

            return newSelected;
        });
        setDrawerContent(null);
    };
    const renderMainContainerContent = () => {
        switch (selectedButton) {
            case 'dashboard':
                return <div><DataTable /></div>;
            case 'appsync-1':
                return <div>Content for Appsync 1</div>;
            case 'appsync-2':
                return <div>Content for Appsync 2</div>;
            default:
                return <div><DataTable /></div>;
        }
    };

    return (
        <div className="screen">
            <Header toggleUserDetails={toggleUserDetails} />
            <SideBar onSelect={handleSidebarSelect} />
            <UserDetails showUserDetails={showUserDetails} onClose={toggleUserDetails} userDetails={userDetails} />
            {drawerContent && (
                <MainDrawer
                    content={drawerContent}
                    onClose={() => setDrawerContent(null)}
                />
            )}
            <MainContainer content={renderMainContainerContent()} />
        </div>
    );
};

export default Screen;
