import React from 'react';

const styles = {
    Button: {
        cursor: 'pointer',
        top: '297px',
        left: '1112px',
        width: '131px',
        height: '36px',
        padding: '0px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // flexDirection: 'row-reverse',
        gap: '7px',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        backgroundColor: 'rgba(0,0,0,0)',
        color: '#21263c',
        fontSize: '14px',
        fontFamily: 'Poppins',
        lineHeight: '16px',
        textAlign: 'left',
        outline: 'none',
    },
    Icon: {
        fontSize: '21px',
        width: '21px',
        height: '21px',
        color: '#21263c',
        fill: '#21263c',
    },
};

const IconComponent = () => (
    <svg style={styles.Icon} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none">
        </path>
        <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z">
        </path>
    </svg>
);

const defaultProps = {
    label: 'Chat with us',
    IconComponent,
};

const CommonButton = ({ label, IconComponent }) => {
    // Render button with label and icon
    return (
        <button style={styles.Button}>
            <IconComponent style={styles.Icon} />
            <span>{label ?? defaultProps.label}</span>

        </button>
    );
};



export default CommonButton;