import React from 'react';

const styles = {
    Button: {
        cursor: 'pointer',
        top: '296px',
        left: '0px',
        width: '6vw',
        height: '78px',
        padding: '0px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column-reverse',
        gap: '8px',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        backgroundColor: 'rgba(0,0,0,0)',
        color: '#ffffff',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '21px',
        outline: 'none',
    },
    Icon: {
        fontSize: '24px',
        width: '24px',
        height: '24px',
        color: '#ffffff',
        fill: '#e6e7eb',
    },
    Selected: {
        backgroundColor: '#6e1cf4',
    },
};

const IconComponent = () => (
    <svg style={styles.Icon} viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0z">
        </path>
        <path d="M9.5 6.5v3h-3v-3h3M11 5H5v6h6V5zm-1.5 9.5v3h-3v-3h3M11 13H5v6h6v-6zm6.5-6.5v3h-3v-3h3M19 5h-6v6h6V5zm-6 8h1.5v1.5H13V13zm1.5 1.5H16V16h-1.5v-1.5zM16 13h1.5v1.5H16V13zm-3 3h1.5v1.5H13V16zm1.5 1.5H16V19h-1.5v-1.5zM16 16h1.5v1.5H16V16zm1.5-1.5H19V16h-1.5v-1.5zm0 3H19V19h-1.5v-1.5zM22 7h-2V4h-3V2h5v5zm0 15v-5h-2v3h-3v2h5zM2 22h5v-2H4v-3H2v5zM2 2v5h2V4h3V2H2z">
        </path>
    </svg>
);

const defaultProps = {
    label: 'Widgets',
    IconComponent,
};

const Widget = ({ isSelected, onClick, label, IconComponent }) => {
    return (
        <button
            style={{
                ...styles.Button,
                ...(isSelected ? styles.Selected : {}),
            }}
            onClick={onClick}
        >
            <span>{label ?? defaultProps.label}</span>
            {IconComponent ? <IconComponent style={styles.Icon} /> : <defaultProps.IconComponent />}
        </button>
    );
};

export default Widget;